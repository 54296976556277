@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-Roboto scroll-smooth;
  }

  html {
    @apply bg-light-blue overflow-x-hidden scroll-smooth;
  }
}

@layer components {
  .container {
    @apply w-[90%] max-w-[1440px] mx-auto;
  }

  .rounded-btn {
    @apply text-white uppercase text-xs lg:text-sm xl:text-base bg-green-to-blue block font-bold py-2 px-6 xl:px-7 rounded-full;
    box-shadow: 7px 8px 11px 0px rgb(2 116 111 / 11%);
    background: linear-gradient(180deg, #4EACDA 0%, #5EECC1 100%),
    linear-gradient(180deg, #5EECC1 0%, #4EACDA 100%);
    border: 4px solid;
    border-image-source: linear-gradient(180deg, #4EACDA 0%, #5EECC1 100%);
  }

  .text-shadow-1 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  /* .text-base {
    
  } */

  .text-48px {
    @apply text-2xl md:text-3xl lg:text-4xl xl:text-5xl;
  }

  .text-16px {
    @apply text-xs lg:text-sm xl:text-base;
  }

  .text-20px {
    @apply text-sm lg:text-base xl:text-xl;
  }

  .text-24px {
    @apply text-base md:text-lg lg:text-xl xl:text-2xl;
  }

  /* FORM */

  .form-input {
    @apply w-full h-8 lg:h-10 xl:h-12 rounded-full bg-white px-6 outline-none text-xs lg:text-sm;
  }
}

.black-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 110 !important;
  transition: all 0.4s ease;
  opacity: 0;
  pointer-events: none;
}
.black-screen.show {
  opacity: 1;
  pointer-events: visible;
}

.hide-icon {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.howDoWeWorkCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* CHECKBOX */

.checkbox input.checkbox-type {
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #4c72f1;
  transition: all 0.25s ease;
}

.checkbox input.checkbox-type:checked {
  background-color: #4c72f1;
}

.checkbox input.checkbox-type::before {
  content: "\2713";
  font-size: 12px;
  color: white;
  transition: all 0.25s ease;
  scale: 1;
}
/* .checkbox input[type="checkbox"]:checked:before {
  scale: 1;
} */
